import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

const Content = styled.div`
  margin: 2rem auto 0 auto;
  width: 960px;

  h2 {
    margin: 4rem 0 1rem 0;
    color: var(--color-gray-50);
  }
  p {
    margin-bottom: 1rem;
    color: var(--color-gray-400);
  }
  a {
    text-decoration: none;
    border-bottom: 1px solid var(--color-pink-400);
    color: var(--color-gray-200);
  }
`

const Privacy = () => {
  return (
    <Layout>
      <Content>
        <SEO title="Privacy policy" />
        <div className="legal-page">
          <h1>Privacy policy</h1>
          <p>
            Backfill (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the{' '}
            <a href="https://backfill.dev/"></a>
            <a href="https://backfill.dev">https://backfill.dev</a> website (the &quot;Service&quot;).
          </p>
          <p>
            This page informs you of our policies regarding the collection, use, and disclosure of personal data when
            you use our Service and the choices you have associated with that data.
          </p>
          <p>We will not use or share your information with anyone except as described in this Privacy Policy.</p>
          <p>
            <strong>
              We use your data to provide and improve the Service. By using the Service, you agree to the collection and
              use of information in accordance with this policy.
            </strong>{' '}
            Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as
            in our Terms and Conditions, accessible at <a href="https://backfill.dev/terms"></a>
            <a href="https://backfill.dev/terms">https://backfill.dev/terms</a>.
          </p>
          <h2>
            <strong>Definitions:</strong>
          </h2>
          <p>
            <strong>Personal Data</strong> means data about a living individual who can be identified from those data
            (or from those and other information either in our possession or likely to come into our possession).
          </p>
          <p>
            <strong>Usage Data</strong> is data collected automatically either generated by the use of the Service or
            from the Service infrastructure itself (for example, the duration of a page visit).
          </p>
          <p>
            <strong>Cookies</strong> are small pieces of data stored on a User&rsquo;s device.
          </p>
          <p>
            <strong>Data Controller</strong> means a person who (either alone or jointly or in common with other
            persons) determines the purposes for which and the manner in which any personal data are, or are to be,
            processed.
          </p>
          <p>For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
          <p>
            <strong>Data Processor</strong> (or Service Provider) means any person (other than an employee of the Data
            Controller) who processes the data on behalf of the Data Controller.
          </p>
          <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
          <p>
            <strong>Data Subject</strong> is any living individual who is the subject of Personal Data.
          </p>
          <p>
            The <strong>User</strong> is the individual using our Service. The User corresponds to the Data Subject, who
            is the subject of Personal Data.
          </p>

          <h2>
            <strong>Information Collection And Use</strong>
          </h2>
          <p>
            We collect several different types of information for various purposes to provide and improve our Service to
            you.
          </p>
          <h2>
            <strong>Personal data</strong>
          </h2>
          <p>
            While using our Service, we may ask you to provide us with certain personally identifiable information that
            can be used to contact or identify you (&quot;Personal Data&quot;). Personally identifiable information may
            include, but is not limited to: email address, first and last name, cookies and usage data.
          </p>
          <p>
            We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or instructions provided in any email we send.
          </p>

          <h2>
            <strong>Usage Data</strong>
          </h2>
          <p>
            We may also collect information how the Service is accessed and used (&quot;Usage Data&quot;). This Usage
            Data may include information such as your computer&#39;s Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the
            time spent on those pages, unique device identifiers and other diagnostic data.
          </p>

          <h2>
            <strong>Tracking &amp; Cookies Data</strong>
          </h2>
          <p>
            We use cookies and similar tracking technologies to track the activity on our Service and hold certain
            information.
          </p>
          <p>
            Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are
            sent to your browser from a website and stored on your device. Tracking technologies also used are beacons,
            tags, and scripts to collect and track information and to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>
              <strong>Session Cookies:</strong> We use Session Cookies to operate our Service.
            </li>
            <li>
              <strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various
              settings.
            </li>
            <li>
              <strong>Security Cookies:</strong> We use Security Cookies for security purposes.
            </li>
          </ul>
          <h2>
            <strong>Customer Data</strong>
          </h2>
          <p>
            Customer Data may include, without limitation, (a) personal information such as names, and email addresses
            of your end user customers, potential customers and other users of and visitors to your websites, apps and
            other properties. Information may be input into the Service by you or collected by the Service using scripts
            and other code implemented on such properties.
          </p>
          <p>
            We will only process Customer Data for the purposes set forth in your agreement with us for the provisioning
            of the Service.
          </p>

          <h2>
            <strong>Use of Data</strong>
          </h2>
          <p>Backfill uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain our Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve our Service</li>
            <li>To monitor the usage of our Service</li>
            <li>To detect, prevent and address technical issues</li>
            <li>
              To provide you with news, special offers and general information about other goods, services and events
              which we offer that are similar to those that you have already purchased or enquired about unless you have
              opted not to receive such information
            </li>
          </ul>

          <h2>
            <strong>Retention of Data</strong>
          </h2>
          <p>
            Backfill will retain your Personal Data only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
            disputes, and enforce our legal agreements and policies.
          </p>
          <p>
            Backfill will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
            shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain this data for longer time periods.
          </p>
          <h2>
            <strong>Transfer Of Data</strong>
          </h2>
          <p>
            Your information, including Personal Data, may be transferred to &mdash; and maintained on &mdash; computers
            located outside of your state, province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside United States and choose to provide information to us, please note that we
            transfer the data to United States and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of such information represents your
            agreement to that transfer.
          </p>
          <p>
            Backfill will take all steps reasonably necessary to ensure that your data is treated securely and in
            accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization
            or a country unless there are adequate controls in place including the security of your data and other
            personal information.
          </p>
          <p>
            Personal data is transferred to servers maintained by Amazon Web Services (AWS). AWS is certified under the
            EU-US Privacy Shield, ensuring that we can transfer data outside the EEA in a compliant way.
          </p>
          <h2>
            <strong>Disclosure Of Data</strong>
          </h2>

          <h4>
            <strong>Business Transaction</strong>
          </h4>
          <p>
            If Backfill is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We
            will provide notice before your Personal Data is transferred and becomes subject to a different Privacy
            Policy.
          </p>

          <h4>
            <strong>Disclosure for Law Enforcement</strong>
          </h4>
          <p>
            Under certain circumstances, Backfill may be required to disclose your Personal Data if required to do so by
            law or in response to valid requests by public authorities (e.g. a court or a government agency).
          </p>

          <h4>
            <strong>Legal Requirements</strong>
          </h4>
          <p>Backfill may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of Backfill</li>
            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>To protect the personal safety of users of the Service or the public</li>
            <li>To protect against legal liability</li>
          </ul>
          <h2>
            <strong>Security Of Data</strong>
          </h2>
          <p>
            The security of your data is important to us, but remember that no method of transmission over the Internet,
            or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
            protect your Personal Data, we cannot guarantee its absolute security.
          </p>

          <h2>
            <strong>Your Rights</strong>
          </h2>
          <p>
            Backfill aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your
            Personal Data.
          </p>
          <p>
            Whenever made possible, you can update your Personal Data directly within your account settings section. If
            you are unable to change your Personal Data, please contact us to make the required changes.
          </p>
          <p>
            If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our
            systems, please contact us.
          </p>
          <p>In certain circumstances, you have the right:</p>
          <ul>
            <li>To access and receive a copy of the Personal Data we hold about you</li>
            <li>To rectify any Personal Data held about you that is inaccurate</li>
            <li>To request the deletion of Personal Data held about you</li>
          </ul>
          <p>
            You have the right to data portability for the information you provide to Backfill. You can request to
            obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.
          </p>
          <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
          <h2>
            <strong>Service Providers</strong>
          </h2>
          <p>
            We may employ third party companies and individuals to facilitate our Service (&quot;Service
            Providers&quot;), to provide the Service on our behalf, to perform Service-related services or to assist us
            in analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>

          <h2>
            <strong>Analytics</strong>
          </h2>
          <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

          <h2>
            <strong>Google Analytics</strong>
          </h2>
          <p>
            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
            Google uses the data collected to track and monitor the use of our Service. This data is shared with other
            Google services. Google may use the collected data to contextualize and personalize the ads of its own
            advertising network.
          </p>
          <p>
            You can opt-out of having made your activity on the Service available to Google Analytics by installing the
            Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
            analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.
          </p>
          <p>
            For more information on the privacy practices of Google, please visit the{' '}
            <a href="http://www.google.com/intl/en/policies/privacy/">Google Privacy &amp; Terms web page</a>.
          </p>

          <h2>
            <strong>Google AdWords</strong>
          </h2>
          <p>Google AdWords remarketing service is provided by Google Inc.</p>
          <p>
            You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by
            visiting the <a href="http://www.google.com/settings/ads">Google Ads Settings page</a>.
          </p>
          <p>
            Google also recommends installing the{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a> for your web
            browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data
            from being collected and used by Google Analytics.
          </p>
          <p>
            For more information on the privacy practices of Google, please visit the{' '}
            <a href="http://www.google.com/intl/en/policies/privacy/">Google Privacy &amp; Terms web page</a>.
          </p>

          <h2>
            <strong>Payments</strong>
          </h2>
          <p>
            We may provide paid products and/or services within the Service. In that case, we use third-party services
            for payment processing (e.g. payment processors).
          </p>
          <p>
            We will not store or collect your payment card details. That information is provided directly to our
            third-party payment processors whose use of your personal information is governed by their Privacy Policy.
            These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS
            requirements help ensure the secure handling of payment information.
          </p>
          <p>
            The payment processor we work with is Stripe. Their Privacy Policy can be viewed{' '}
            <a href="https://stripe.com/us/privacy">here</a>.
          </p>

          <h2>
            <strong>Links To Other Sites</strong>
          </h2>
          <p>
            Our Service may contain links to other sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party&#39;s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.
          </p>

          <h2>
            <strong>Security</strong>
          </h2>
          <p>
            The security of your Personal Information is important to us, and we strive to implement and maintain
            reasonable, commercially acceptable security procedures and practices appropriate to the nature of the
            information we store, in order to protect it from unauthorized access, destruction, use, modification, or
            disclosure.
          </p>
          <p>
            However, please be aware that no method of transmission over the internet, or method of electronic storage
            is 100% secure and we are unable to guarantee the absolute security of the Personal Information we have
            collected from you.
          </p>

          <h2>
            <strong>Children&#39;s Privacy</strong>
          </h2>
          <p>Our Service does not address anyone under the age of 13 (&quot;Children&quot;).</p>
          <p>
            We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are
            a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact
            us. If we become aware that we have collected Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>

          <h2>
            <strong>Changes To This Privacy Policy</strong>
          </h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
            effective and update the &quot;effective date&quot; at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.
          </p>

          <h2>
            <strong>Contact Us</strong>
          </h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at{' '}
            <a href="mailto:support@backfill.dev">support@backfill.dev</a>.
          </p>
        </div>
      </Content>
    </Layout>
  )
}

export default Privacy
